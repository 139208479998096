




















import { Component, Prop, Watch } from 'vue-property-decorator';
import Exhibitor from '@/models/graphql/Exhibitor';
import { namespace } from 'vuex-class';
import CmsTableComponent from '@/components/company-cms/CmsTableComponent.vue';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import EntityType from '@/utils/enums/EntityType';
import { mixins } from 'vue-class-component';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';
import GUUID from '@/utils/GUUID';

const exhibitorStore = namespace('ExhibitorStore');

@Component({
  components: { CmsTableComponent },
})
export default class CmsTableWidget extends mixins(VueBaseWidget, VueRegisterStoreWidget) {
  @Prop({ required: false, default: null })
  protected type!: string;

  @Prop({ required: false, default: false })
  protected newEntity!: boolean;

  @Prop({ required: false, default: true })
  protected allowManufacture!: boolean;

  @Prop({ required: false, default: false })
  protected allowExtraProperties!: boolean;

  @Prop({ required: false, default: false })
  protected search!: boolean;

  @Prop({ required: false, default: null })
  protected fields!: string;

  @Prop({ required: false, default: null })
  protected mandatoryFields!: string;

  @Prop({ required: false, default: null })
  protected category!: string;

  @Prop({ required: false, default: null })
  protected subType!: string;

  @exhibitorStore.Getter('fetchAdminPanelExhibitor')
  private adminPanelExhibitor!: Exhibitor;

  @exhibitorStore.Action
  private loadAdminPanelExhibitor!: (payload: { uid: string; authUser: string }) => void;

  private key = '1';

  protected get entityId(): string {
    if (this.entityType === EntityType.SPEAKER) {
      if (this.authUser.speakers && this.authUser.speakers[0]) {
        return this.authUser.speakers[0].uid ?? '';
      }
      return '';
    }
    return this.adminPanelExhibitor.uid ?? '';
  }

  protected get entityType(): EntityType {
    switch (this.type) {
      case 'user':
        return EntityType.USER;
      case 'deal':
        return EntityType.DEAL;
      case 'product':
        return EntityType.PRODUCT;
      case 'largeProduct':
        return EntityType.LARGE_PRODUCT;
      case 'handout':
        return EntityType.HANDOUT;
      case 'boatProduct':
        return EntityType.BOAT_PRODUCT;
      case 'speaker':
        return EntityType.SPEAKER;
      case 'exhibitorVideo':
        return EntityType.EXHIBITOR_VIDEO;
      case 'order':
        return EntityType.ORDER;
      default:
        return EntityType.USER;
    }
  }

  created(): void {
    this.setDataConfig();
  }

  @Watch('entityType')
  private loadData(): void {
    this.key = GUUID.uuidv4();
    const { companyId } = this.$route.params;
    this.$root.$refs.mainHeader = this;
    window.addEventListener('scroll', this.handleScroll);
    if (companyId
      && ((this.adminPanelExhibitor && !this.adminPanelExhibitor.uid)
        || (this.adminPanelExhibitor && this.adminPanelExhibitor.uid !== companyId))) {
      this.loadAdminPanelExhibitor({ uid: companyId, authUser: this.authUser.uid });
    }
  }

  private handleScroll(): void {
    switch (this.breakpoint.value) {
      case 'xl':
      case 'lg':
      case 'md':
        this.$data.fixed = window.scrollY >= 230;
        break;
      case 'sm':
      case 'xs':
        this.$data.fixed = window.scrollY >= 142;
        break;
      default:
        this.$data.fixed = window.scrollY >= 142;
    }
  }
}
